import { FC } from "react";
import { MainProps } from "./model/types";
import clsx from "./ui/index.module.css";
import { Logo } from "../../../shared/logo/Logo";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../shared/button/Button";

const Main: FC<MainProps> = ({ children, src }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear(); // Clear all local storage
    navigate("/"); // Redirect to main page
    window.location.reload(); // Reload to ensure all states are reset
  };

  return (
    <div className={`${clsx.main_wrapper}`}>
      <div className={clsx.main_left}>
        <div className={clsx.header}>
          <Logo onClick={() => navigate("/")}>
            <span>flat</span>
            <span>sharing</span>
          </Logo>
          <Button 
            onClick={handleLogout}
            $border
            style={{ 
              position: 'absolute',
              right: '0',
              backgroundColor: 'transparent',
              transform: 'scale(0.7)',
              transformOrigin: 'right center'
            }}
          >
            Log out
          </Button>
        </div>
        {children}
      </div>
      {src && <img className={clsx.images} src={src} alt="auth" />}
    </div>
  );
};

export default Main;
