export const enum STATUS_LISTING {
  ARCHIVED = "archived",
  CREATED = "created",
  EDITED = "edited",
  FINISHED = "finished",
  SENT_TO_ADMINS = "sent_to_admins",
  UPDATE_MY_PICTURE = "update_sent_to_admins",
  POSTED = "posted",
  UPDATED = "updated",
  UPDATE_POSTED = "update_posted",
  DUMPED = "dumped",
  INFO_NEEDED = "info_needed",
  ACTIVE = "active",
}
