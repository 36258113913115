import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { ReactComponent as Camera } from "../../assets/icons/camera.svg";
import clsx from "./styles.module.css";
import { UsedListingPicture } from "../../pages/editAdvert/model/types";

interface Props {
  onImageChanger?: (data: File[]) => void;
  required?: boolean;
  images?: UsedListingPicture[];
  imageFiles: FileList | null;
  setImageFiles: (files: FileList | null) => void;
}

const Uploader: FC<Props> = ({ onImageChanger, required, images = [], imageFiles, setImageFiles }) => {
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  
  const handleImageUpload = (files: FileList) => {
    const currentLength = imageFiles ? Array.from(imageFiles).length : 0;
    const newImageFiles = Array.from(files).slice(0, 8 - currentLength);
    
    // Create a new DataTransfer object to combine files
    const dataTransfer = new DataTransfer();
    
    // Add existing files if any
    if (imageFiles) {
      Array.from(imageFiles).forEach(file => dataTransfer.items.add(file));
    }
    
    // Add new files
    newImageFiles.forEach(file => dataTransfer.items.add(file));
    
    setImageFiles(dataTransfer.files);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      handleImageUpload(files);
    }
  };

  const handleDeleteImage = (indexToDelete: number) => {
    if (imageFiles) {
      const dataTransfer = new DataTransfer();
      Array.from(imageFiles).forEach((file, index) => {
        if (index !== indexToDelete) {
          dataTransfer.items.add(file);
        }
      });
      setImageFiles(dataTransfer.files);
    }
  };

  const handleDragStart = (index: number) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (dropIndex: number) => {
    if (draggedIndex === null || !imageFiles) return;

    const files = Array.from(imageFiles);
    const draggedFile = files[draggedIndex];
    
    // Remove the dragged item
    files.splice(draggedIndex, 1);
    // Insert it at the new position
    files.splice(dropIndex, 0, draggedFile);

    // Create new FileList
    const dataTransfer = new DataTransfer();
    files.forEach(file => dataTransfer.items.add(file));
    setImageFiles(dataTransfer.files);
    setDraggedIndex(null);
  };

  return (
    <div className={clsx.uploader_wrapper}>
      <div className={clsx.imageGallery}>
        {imageFiles && Array.from(imageFiles).map((file, index) => (
          <div 
            key={index} 
            className={clsx.imageContainer}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={handleDragOver}
            onDrop={() => handleDrop(index)}
          >
            <img
              src={URL.createObjectURL(file)}
              alt={`Uploaded ${index + 1}`}
              className={clsx.imagePreview}
            />
            <button
              className={clsx.deleteButton}
              onClick={() => handleDeleteImage(index)}
              type="button"
            >
              ×
            </button>
          </div>
        ))}
        {images.map((file, index) => (
          <div key={index + (imageFiles ? imageFiles.length : 0)} className={clsx.imageContainer}>
            <img
              src={file.picture_url}
              alt={`Uploaded ${index + 1}`}
              className={clsx.imagePreview}
            />
          </div>
        ))}
      </div>
      {(!imageFiles || imageFiles.length < 8) && (
        <div className={clsx.uploader}>
          <input
            required={required && (!imageFiles || imageFiles.length === 0)}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
            id="fileInput"
            multiple
          />
          <label htmlFor="fileInput" className={clsx.uploadButton}>
            <Camera />
          </label>
        </div>
      )}
      {imageFiles && imageFiles.length >= 8 && (
        <p className={clsx.errorMessage}>You can only upload up to 8 photos.</p>
      )}
    </div>
  );
};

export default Uploader;
