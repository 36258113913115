import { FC } from "react";
import { StepProps } from "./model/types";
import styles from "./ui/index.module.css";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Text } from "../../../shared/Text/Text";
import { loadStripe } from "@stripe/stripe-js";
import { Linked } from "../../../shared/Linked/Linked";
import { useModal } from "../../../app/helpers/hooks/useModal";
import Payment from "../../../features/payment/Payment";

const Complete: FC<StepProps> = () => {
  const { isOpen, openModal, closeModal, ModalComponent } = useModal();
  return (
    <div className={styles.step2_wrapper}>
      <div style={{ marginTop: "15%" }} className={styles.step1_titles}>
        <SecondTitle fz="40px">Last step</SecondTitle>
        <Text>
          We will contact you by email after we complete your verification.
          If you have any questions, please contact us at{" "}
          <Linked href="mailto:contact@flatsharingcommunity.com">
            contact@flatsharingcommunity.com
          </Linked>
        </Text>

        {/* <Linked
          onClick={() => {
            openModal();
          }}
          $weight="500"
          $underlinew
        >
          I have already paid in Telegram
        </Linked> */}
      </div>
      <ModalComponent>
        <Payment
          isOpen={isOpen}
          openModal={openModal}
          closeModal={closeModal}
        />
      </ModalComponent>
    </div>
  );
};

export default Complete;
