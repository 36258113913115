import Auth from "../../entities/auth/auth/Auth";
import Details from "../../entities/details/Details";
import AddFlat from "../../pages/addFlat/AddFlat";
import Browse from "../../pages/browse/Browse";
import Home from "../../pages/home/HomePage";
import PersonalAccount from "../../pages/account/PersonalAccount";
import NotFound from "../../pages/notFound/NotFound";
import { EditAdvert } from "../../pages/editAdvert";
import HowItWorksNotAuth from "../../pages/howItWorks/howItWorksNotAuth/HowItWorksNotAuth";
import HowItWorksAuth from "../../pages/howItWorks/howItWorksAuth/HowItWorksAuth";
import { Blog, DetailsBlog } from "../../pages/blog";
import PasswordRecovery from "../../pages/resetPassword/PasswordRecovery";

interface RouteType {
  path: string;
  component: React.ComponentType<any>;
  id: number;
}

const user = localStorage.getItem("token");

export const PUBLIC_ROUTES: RouteType[] = [
  { path: "/", component: Home, id: 1 },
  {
    path: "/registration",
    component: Auth,
    id: 2,
  },
  {
    path: "/details/:id",
    component: Details,
    id: 3,
  },

  {
    path: "/browse",
    component: Browse,
    id: 4,
  },

  {
    path: "/add_apartment",
    component: AddFlat,
    id: 5,
  },
  { path: "/account/:slug", component: PersonalAccount, id: 6 },
  { path: "*", component: NotFound, id: 7 },
  {
    path: "/account/me/advert/:id",
    component: EditAdvert,
    id: 8,
  },
  {
    path: "/how_it_works",
    component: user ? HowItWorksAuth : HowItWorksNotAuth,
    id: 9,
  },
  {
    path: "/blog",
    component: Blog,
    id: 10,
  },
  {
    path: "/blog/:id/:name",
    component: DetailsBlog,
    id: 11,
  },
  {
    path: "/password-recovery/:code",
    component: PasswordRecovery,
    id: 12,
  },
  {
    path: "/password-recovery/:code",
    component: PasswordRecovery,
    id: 13,
  },
];
