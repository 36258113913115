import { FC, useEffect, useState } from 'react';
import styles from './SuccessPopup.module.css';

interface SuccessPopupProps {
  show: boolean;
  onHide: () => void;
  message?: string;
}

export const SuccessPopup: FC<SuccessPopupProps> = ({ 
  show, 
  onHide, 
  message = 'Success' 
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
        setTimeout(() => {
          onHide();
        }, 300);
      }, 3000);
    }
  }, [show, onHide]);

  if (!show) return null;

  return (
    <div className={`${styles.success_popup} ${!isVisible ? styles.hide : ''}`}>
      {message}
    </div>
  );
}; 