import { FC } from "react";
import { useModal } from "../../../app/helpers/hooks/useModal";
import { StepProps } from "./model/types";
import styles from "./ui/index.module.css";
import Login from "../login/Login";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Input } from "../../../shared/input/Input";
import { Title } from "../../../shared/title/Title";
import { Text } from "../../../shared/Text/Text";
import { Linked } from "../../../shared/Linked/Linked";

interface Step1Props extends StepProps {
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

const Step1: FC<Step1Props> = ({
  formData,
  handleChange,
  placeholder,
  titles,
  name,
  errors,
  checked,
  setChecked,
}) => {
  const { isOpen, openModal, closeModal, ModalComponent } = useModal();
  
  return (
    <div className={styles.step2_wrapper}>
      <div className={styles.step1_titles}>
        <Title>Registration</Title>
        <Text style={{ display: "flex", flexWrap: "wrap", gap: "3px" }}>
          I already have an account.{" "}
          <Linked onClick={openModal} style={{ textDecoration: "underline" }}>
            Sign In
          </Linked>{" "}
          to my personal account
        </Text>
        <SecondTitle fz="22px" style={{ fontFamily: "Roboto Condensed" }}>
          Create your account
        </SecondTitle>
      </div>
      <div className={styles.inputs_wrapper}>
        <Input
          $error={errors?.email ? true : false}
          required
          placeholder="Email"
          value={formData.email}
          type="text"
          name="email"
          onChange={handleChange}
        />
        <Text className={styles["error-text"]} style={{ color: "red", fontSize: "14px" }}>
          {errors?.email ? errors.email : ""}
        </Text>

        <Input
          required
          $error={errors?.password ? true : false}
          type={checked ? "text" : "password"}
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Password"
        />
        <Text className={styles["error-text"]} style={{ color: "red", fontSize: "14px" }}>
          {errors?.password ? errors.password : ""}
        </Text>

        <Input
          required
          $error={errors?.password_confirm ? true : false}
          type={checked ? "text" : "password"}
          name="password_confirm"
          value={formData.password_confirm}
          onChange={handleChange}
          placeholder="Confirm Password"
        />
        <Text className={styles["error-text"]} style={{ color: "red", fontSize: "14px" }}>
          {errors?.password_confirm ? errors.password_confirm : ""}
        </Text>

        <div className={styles.check}>
          <input
            onChange={() => setChecked(!checked)}
            type="checkbox"
            id="check"
          />
          <label htmlFor="check">Show password</label>
        </div>
      </div>
      
      <ModalComponent>
        <Login isOpen={isOpen} openModal={openModal} closeModal={closeModal} />
      </ModalComponent>
    </div>
  );
};

export default Step1;
