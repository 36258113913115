import React, { FC, useEffect, useState } from "react";
import { StepProps } from "./model/types";
import clsx from "./ui/index.module.css";
import { Text } from "../../../shared/Text/Text";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Button } from "../../../shared/button/Button";
import { useVerifyAccountMutation } from "../../../app/redux/auth/authApi";
import { useGetUserEmailQuery } from "../../../app/redux/auth/authApi";
import { useNavigate } from "react-router-dom";

const Step2: FC<StepProps> = ({ successMessage }) => {
  const [successVerify, setSuccessVerify] = useState(false);
  const [cooldown, setCooldown] = useState(0);
  const navigate = useNavigate();

  const { data: userResponse } = useGetUserEmailQuery({});
  const [verifyAccount] = useVerifyAccountMutation();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/?step=1");
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (cooldown > 0) {
      timer = setInterval(() => {
        setCooldown((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [cooldown]);

  const handleResendVerification = () => {
    verifyAccount({ data: { email: userResponse?.email } });
    setSuccessVerify(true);
    setCooldown(60); // Start 60 second cooldown
  };

  return (
    <div className={clsx.step1_wrapper}>
      <div className={clsx.step1_titles}>
        <SecondTitle fz="22px">Verify your email</SecondTitle>
      </div>
      <div className={clsx.inputs_wrapper}>
        <Text style={{ color: "green", fontSize: "14px" }}>
          We've sent a confirmation email to {userResponse?.email}, please verify your account.
        </Text>
        
        <div className={clsx.verify_actions}>
          <Text>You need to verify your account before continuing</Text>
          <Button
            onClick={handleResendVerification}
            disabled={cooldown > 0}
            $bg
            $icon
          >
            {cooldown > 0 
              ? `Resend available in ${cooldown}s` 
              : "Resend verification email"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Step2;
