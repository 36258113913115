import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface User {
  password: string;
  email: string;
  is_active: boolean;
  is_superuser: boolean;
  is_verified: boolean;
  is_accepted: boolean;
}

interface RegisterResponse {
  id: string;
  password: string;
  email: string;
  is_active: boolean;
  is_superuser: boolean;
  is_verified: boolean;
  is_accepted: boolean;
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_FLAT_API,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Auth'],

  endpoints: (builder) => ({
    register: builder.mutation<RegisterResponse, { user: User }>({
      query: ({ user }) => ({
        url: "auth/register",
        method: "POST",
        body: user,
      }),
    }),

    verifyAccount: builder.mutation({
      query: ({ data }) => ({
        url: "/auth/request-verify-token",
        method: "POST",
        body: data,
      }),
    }),

    verify: builder.mutation({
      query: ({ data }) => ({
        url: "/auth/verify",
        method: "POST",
        body: data,
      }),
    }),

    login: builder.mutation({
      query: ({ user }) => ({
        url: "auth/jwt/login",
        method: "POST",
        body: user,
      }),
    }),

    forgotPassword: builder.mutation({
      query: ({ data }) => ({
        url: "auth/forgot-password",
        method: "POST",
        body: data,
      }),
    }),

    resetPassword: builder.mutation({
      query: ({ data }) => ({
        url: "auth/reset-password",
        method: "POST",
        body: data,
      }),
    }),
    
    payment: builder.mutation({
      query: ({ data }) => ({
        url: "payments/link",
        method: "POST",
        body: data,
      }),
    }),

    logout: builder.mutation({
      query: () => ({
        url: "auth/jwt/logout",
        method: "POST",
      }),
    }),

    editUserInfo: builder.mutation({
      query: ({ user }) => ({
        url: "user_infos/me",
        method: "PATCH",
        body: user,
      }),
    }),

    editUser: builder.mutation({
      query: ({ user }) => ({
        url: "users/me",
        method: "PATCH",
        body: user,
      }),
    }),

    getUser: builder.query({
      query: () => "/user_infos/me",
    }),

    getUserEmail: builder.query({
      query: () => "/users/me",
    }),
    
    getUserById: builder.query({
      query: ({ id }) => ({
        url: `/user_infos/${id}`,
        headers: {
          'Accept-Language': 'en'
        }
      }),
    }),

    updateUserPicture: builder.mutation({
      query: ({ data }) => {
        return {
          url: "/user_infos/update_my_picture",
          method: "PATCH",
          body: data,
        };
      },
    }),
    
    createUserInfo: builder.mutation({
      query: ({ data }) => ({
        url: "/user_infos/me",
        method: "POST",
        body: data,
      }),
    }),

    userAccessRequest: builder.mutation({
      query: ({ data }) => ({
        url: "/user_access_requests",
        method: "POST",
        body: data,
      }),
    }),

  }),
});

export const updateAuthApiToken = () => {
  authApi.util.resetApiState();
};

export const {
  useRegisterMutation,
  useLoginMutation,
  useVerifyAccountMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useEditUserInfoMutation,
  useGetUserQuery,
  useGetUserByIdQuery,
  useUpdateUserPictureMutation,
  useLogoutMutation,
  useGetUserEmailQuery,
  useEditUserMutation,
  usePaymentMutation,
  useVerifyMutation,
  useCreateUserInfoMutation,
  useUserAccessRequestMutation,
} = authApi;
