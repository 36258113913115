import { FC, useEffect } from "react";
import { Button } from "../../shared/button/Button";
import { LessorAvatar } from "../../shared/lessor/LessorAvatar";
import { SecondTitle } from "../../shared/secondTitle/SecondTitle";
import { Text } from "../../shared/Text/Text";
import styles from "./ui/index.module.css";
import { ModalProps } from "../auth/login/model/types";
import closeIcon from "../../assets/icons/close.svg";

interface Props extends ModalProps {
  socialNetworks: {
    Instagram: string | null;
    LinkedIn: string | null;
    WhatsApp: string | null;
    Telegram: string | null;
  };
  data: any;
  closeModal: () => void;
}

const renderButton = (name: string, value: string | null, handleButtonClick: (value: string, name: string) => void) => {
  if (!value) return null;
  
  if (name === "Telegram") {
    return (
      <Button
        $icon
        $iconColor
        $iconBgColor="rgba(40, 40, 40, 1)"
        className={styles.social_btn}
        onClick={() => handleButtonClick(value, name)}
      >
        {name}
      </Button>
    );
  }

  return (
    <Button
      $icon
      $iconColor
      $iconBgColor="rgba(40, 40, 40, 1)"
      className={styles.social_btn}
    >
      {value}
    </Button>
  );
};

const renderSocialLink = (name: string, value: string | null) => {
  if (!value) return null;
  
  return (
    <Text color="white" fw="400" className={styles.social_link}>
      {value}
    </Text>
  );
};

const ContactsPopUp: FC<Props> = ({ socialNetworks, data, closeModal }) => {
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [closeModal]);

  const handleButtonClick = (value: string, name: string) => {
    if (name === "Telegram") {
      window.open(`https://t.me/${value}`, "_blank");
    }
  };

  const handlePopupClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    closeModal();
  };

  return (
    <div 
      onClick={closeModal}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000
      }}
    >
      <div className={styles.header_container} onClick={handlePopupClick}>
        <div className={styles.close_icon} onClick={handleCloseClick}>
          <img src={closeIcon} alt="close icon" />
        </div>
        <div className={styles.header}>
          <LessorAvatar
            className={styles.header_img}
            src={
              data.data.picture_url || "https://i.pinimg.com/564x/25/ee/de/25eedef494e9b4ce02b14990c9b5db2d.jpg"
            }
          />
          <div>
            <SecondTitle>{data.data.full_name}</SecondTitle>
            <Text>{data.data.description}</Text>
          </div>
        </div>
        <div className={styles.container_links}>
          <div>
            <SecondTitle className={styles.links_btn} color="white">
              Book an apartment
            </SecondTitle>
            <Text color="white" fw="400">
              Contact {data?.data?.full_name} in Telegram:
            </Text>
            <div className={styles.container_buttons}>
              {renderButton("Telegram", data.data.tg_username, handleButtonClick)}
            </div>
          </div>

          <div>
            <Text color="white" fw="400">
              Or in social networks:
            </Text>
            <div className={styles.social_links_container}>
              {renderSocialLink("Instagram", socialNetworks.Instagram)}
              {renderSocialLink("LinkedIn", socialNetworks.LinkedIn)}
              {renderSocialLink("WhatsApp", socialNetworks.WhatsApp)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsPopUp;
