import React, { FC } from "react";
import { StepProps } from "./model/types";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";

import styles from "./ui/index.module.css";

const Step3: FC<StepProps> = ({ formData, handleChange, titles }) => {
  return (
    <div className={styles.step3_wrapper}>
      <div className={styles.step3_second_wrapper}>
        <div className={styles.step3_titles}>
          <SecondTitle style={{ fontFamily: "Roboto Condensed" }}>
            {titles}
          </SecondTitle>
        </div>
        <div className={styles.step3_radio}>
          <div>
            <input
              type="radio"
              name="meet"
              value={"true"}
              checked={formData.meet === "true"}
              onChange={handleChange}
            />
            <p>Yes</p>
          </div>
          <div>
            <input
              type="radio"
              name="meet"
              value={"false"}
              checked={formData.meet === "false"}
              onChange={handleChange}
            />
            <p>No</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
