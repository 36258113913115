import React, { ChangeEvent, DragEvent, useEffect, useState } from "react";
import {
  ErrorResponse,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import clsx from "./ui/index.module.css";

import auth1 from "../../../assets/png/auth1.png";
import auth2 from "../../../assets/png/auth2.png";
import auth3 from "../../../assets/png/auth3.png";

import Main from "../steps/Main";
import Step1 from "../steps/Step1";
import Step2 from "../steps/Step2";
import Step3 from "../steps/Step3";
import Step5 from "../steps/Step5";
import ImageUploader from "../steps/ImageUploader";
import InputStep from "../steps/InputStep";
import Complete from "../steps/Complete";
import SocialMedia from "../steps/SocialMedia";
import { Text } from "../../../shared/Text/Text";
import { Button } from "../../../shared/button/Button";
import TelegramPayment from "../steps/TelegramPayment";

import {
  useCreateUserInfoMutation,
  useEditUserInfoMutation,
  useGetUserEmailQuery,
  useGetUserQuery,
  useLoginMutation,
  usePaymentMutation,
  useRegisterMutation,
  useUpdateUserPictureMutation,
  useVerifyAccountMutation,
  useVerifyMutation,
  updateAuthApiToken,
} from "../../../app/redux/auth/authApi";
import { FormDataTypes } from "./model/types";
import { emailRegEx } from "../../../app/constants/regex";

const Auth: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [formData, setFormData] = useState<FormDataTypes>({
    id: "",
    email: "",
    password: "",
    password_confirm: "",
    description: "",
    address: "",
    firstName: "",
    lastName: "",
    profession: "",
    instagram: "",
    linkedin: "",
    meet: "",
    where_to_rent: "",
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(() => {
    const searchParams = new URLSearchParams(location.search);
    const stepId = parseInt(searchParams.get("step") || "1", 10);
    return stepId;
  });

  const [register] = useRegisterMutation();
  const [veryifyAccount] = useVerifyAccountMutation();
  const [verify] = useVerifyMutation();
  const [edit] = useEditUserInfoMutation();
  const [login] = useLoginMutation();
  const [imageUpdate] = useUpdateUserPictureMutation();
  const [payment] = usePaymentMutation();
  
  const {data: userInfo} = useGetUserQuery({});
  const { data: user } = useGetUserEmailQuery({});

  const [checked, setChecked] = useState(false);

  const handleVerify = async () => {
    if (token) {
      const res = await verify({ data: { token: token } });
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const stepId = parseInt(searchParams.get("step") || "1", 10);
    setCurrentStep(stepId);
  }, [location.search]);

  useEffect(() => {
    if (user?.is_verified === false) {
      handleVerify();
    }
  }, [user?.is_verified]);

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    if (file) {
      setImageFile(file);
    }
  };

  const handlePayment = async () => {
    const data = {
      amount: 28,
      description: `Access to FlatSharing Community for 1 year for ${user?.email} ${user?.id}`,
      recurring_period: "year",
      trial_days: 7,
      quantity: 1,
      user_id: user?.id,
      period: 366,
    };
    
    const { data: paymentResponse } = await payment({ data: data });
    
    if (paymentResponse) {
      if (currentStep < 9) {
        setSearchParams({
          step: (Number(searchParams.get("step")) + 1).toString(),
        });
        setCurrentStep(currentStep + 1);
        localStorage.setItem("step", JSON.stringify(currentStep + 1));
      }

      // Navigate to payment URL in same window
      window.location.href = paymentResponse.stripe_link;
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const file = e.target.files?.[0];

    if (file) {
      setImageFile(file);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? "" : prevErrors[name],
    }));
  };

  const validateStep = () => {
    const newErrors: { [key: string]: string } = {};

    switch (currentStep) {
      case 1:
        if (!formData.email) {
          newErrors.email = "Email is required";
        } else if (!formData.email.includes("@")) {
          newErrors.email = "Email must contain '@'";
        } else {
          const emailParts = formData.email.split("@");
          if (emailParts.length < 2 || emailParts[1].trim() === "") {
            newErrors.email = "There must be something after the @-sign";
          } else if (!emailRegEx.test(formData.email)) {
            newErrors.email = "Please enter a valid email address";
          }
        }

        // Add password validation for step 1
        if (!formData.password) {
          newErrors.password = "Password is required";
        }
        if (!formData.password_confirm) {
          newErrors.password_confirm = "Password confirm is required";
        }
        if (formData.password !== formData.password_confirm) {
          newErrors.password_confirm = "Passwords must match";
        }
        break;

      case 2:
        if (!formData.password) newErrors.password = "Password is required";
        if (!formData.password_confirm)
          newErrors.password_confirm = "Password confirm is required";
        if (formData.password !== formData.password_confirm)
          newErrors.password_confirm = "Passwords must match";
        break;

      case 3:
        if (!formData.firstName) newErrors.firstName = "First name is required";
        if (!formData.lastName) newErrors.lastName = "Last name is required";
        break;

      case 4:
        if (!formData.where_to_rent)
          newErrors.where_to_rent = "This field is required";
        break;

      case 6:
        if (!formData.description)
          newErrors.description = "This field is required";
        break;

      default:
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateStep()) return;

    if (currentStep === 1) {
      const registerData = {
        email: formData.email,
        password: formData.password,
        is_active: true,
        is_superuser: false,
        is_verified: false,
        is_accepted: false,
      };

      localStorage.setItem("step", JSON.stringify(2));

      const loginData = new FormData();
      loginData.append("username", formData.email);
      loginData.append("password", formData.password);

      try {
        const { error: registerError, data: registerResult } = await register({
          user: registerData,
        });
        const { data: tokens } = await login({ user: loginData });

        if (registerError && "data" in registerError) {
          const newErrors: { [key: string]: string } = {};
          const errorData = registerError as ErrorResponse;

          if (errorData.data.detail === "REGISTER_USER_ALREADY_EXISTS") {
            newErrors.email = "This email already exists";
            setErrors(newErrors);
            navigate("/registration?step=1");
          }
        } else if (registerResult) {
          setFormData((prevData) => ({
            ...prevData,
            id: registerResult.id,
          }));
          veryifyAccount({ data: { email: formData.email } });
          setShowSuccessMessage(true);
        }
        if (tokens) {
          localStorage.setItem("token", tokens.access_token);
          updateAuthApiToken();
          await userInfo?.refetch();
          handleNext();
        }
      } catch (error) {
        console.error("Registration error:", error);
      }
    } else {
      handleNext();
    }
  };

  const handleEdit = async () => {
    if (!validateStep()) return;
    const editedData = {
      full_name:
        formData.firstName && formData.lastName
          ? `${formData.firstName} ${formData.lastName}`
          : undefined,
      instagram: formData.instagram || undefined,
      linkedin: formData.linkedin || undefined,
      description: formData.description || undefined,
      where_to_let: formData.where_to_rent || undefined,
      contact_email: formData.email || undefined,
      meet:
        formData.meet && typeof formData.meet === "string"
          ? JSON.parse(formData.meet)
          : undefined,
      onboarding_step: currentStep < 9 ? currentStep + 1 : 9,
    };

    if (currentStep < 9) {
      localStorage.setItem("step", JSON.stringify(currentStep + 1));
    }

    try {
      const { data: editResult } = await edit({ user: editedData });
      if (editResult) {
        const form = new FormData();
        if (imageFile) {
          form.append("file", imageFile);
          await imageUpdate({ data: form });
        }
        //navigate("/");
        //window.location.reload();
      }

      if (currentStep < 9) {
        handleNext();
      }
    } catch (error) {}
  };

  const goToStep = (step: number) => {
    navigate(`/registration?step=${step}${step === 2 ? "&reg=true" : ""}`);
  };

  const handleNext = () => {
    if (!validateStep()) return;
    goToStep(currentStep + 1);
  };

  const checkedHook = (status: string | undefined) => {
    // @ts-ignore
    setChecked(status);
    return status;
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Main src={auth1}>
            <Step1
              formData={formData}
              handleChange={handleChange}
              titles="What’s your email?"
              placeholder="Email"
              name="email"
              errors={errors}
              checked={checked}
              setChecked={setChecked}
            />
          </Main>
        );
      case 2:
        return (
          <Main src={auth2}>
            <Step2
              formData={formData}
              handleChange={handleChange}
              successMessage={showSuccessMessage}
              name="password"
              name2="password_confirm"
              titles="Create a password for your account"
              errors={errors}
            />
          </Main>
        );
      case 3:
        return (
          <Main src={auth2}>
            <Step5
              formData={formData}
              handleChange={handleChange}
              name="firstName"
              name2="lastName"
              errors={errors}
            />
          </Main>
        );
      case 4:
        return (
          <Main src={auth2}>
            <InputStep
              name="where_to_rent"
              formData={formData}
              value={formData.where_to_rent}
              handleChange={handleChange}
              titles="Do you want to rent out your apartment? Specify country and city if yes"
              placeholder="Enter your country or city"
              errors={errors}
            />
          </Main>
        );
      case 5:
        return (
          //<Main src={auth3}>
          //  <Step3
          //    formData={formData}
          //    name="_"
          //    handleChange={handleChange}
          //    titles="Would you like to rent out your apartment at the time of departure?"
          //  />
          //</Main>
          <Main src={auth3}>
            <Step3
              formData={formData}
              name="_"
              handleChange={handleChange}
              titles="Are you interested in participating in regular community forums dedicated to travel, visas, relocation and taxes?"
            />
          </Main>
        );
      case 6:
        return (
          <Main src={auth3}>
            <InputStep
              name="description"
              formData={formData}
              value={formData.description}
              handleChange={handleChange}
              placeholder="Enter your profession and your interests"
              titles="Tell us about yourself. Where do you work? What are you interested in?"
              errors={errors}
            />
          </Main>
        );
      case 7:
        return (
          <Main src={auth3}>
            <ImageUploader
              name="img"
              formData={formData}
              handleChange={handleChange}
              handleDrop={handleDrop}
              imageFile={imageFile}
              checkedHook={checkedHook}
            />
          </Main>
        );
      case 8:
        return (
          <Main src={auth3}>
            <TelegramPayment
              formData={formData}
              handleChange={handleChange}
              name="telegram_payment"
            />
          </Main>
        );
      case 9:
        return (
          <Main src={auth3}>
            <Complete
              formData={formData}
              handleChange={handleChange}
              titles="What's your email?"
              placeholder="Email"
              name="email"
              errors={errors}
            />
          </Main>
        );
      case 10:
        return (
          <Main src={auth3}>
            <SocialMedia
              formData={formData}
              setFormData={setFormData}
              // @ts-ignore
              errors={errors}
              name="instagram"
              name2="linkedin"
            />
          </Main>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`${clsx.registrationForm} container`}>
      <form
        id="form1"
        onSubmit={(e) => {
          e.preventDefault();
          if (currentStep === 1) {
            handleSubmit();
          } else if (currentStep >= 2 && currentStep <= 7) {
            handleEdit();
          } else if (currentStep === 8) {
            handleNext();
          } else if (currentStep === 9) {
            handlePayment();
          } else if (currentStep === 10) {
            handleEdit();
            return;
          } else {
            handleNext();
          }
        }}
      >
        {renderStep()}
        <div className={`${clsx.buttons}`}>
          {currentStep !== 1 && currentStep < 7 && (
            <Button
              $border
              type="button"
              style={{ backgroundColor: "transparent" }}
              onClick={() => goToStep(currentStep - 1)}
            >
              Back
            </Button>
          )}
          {currentStep == 2 ? null : currentStep < 7 ? (
            <Button $bg $icon type="submit">
              {currentStep === 1 ? "Create Account" : "Next"}
            </Button>
          ) : currentStep == 7 ? (
            <Button $bg $icon type="submit">
              Join the community
            </Button>
          ) : currentStep == 9 ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Button
                type="button"
                onClick={handlePayment}
                style={{ marginBottom: "20px" }}
                $bg
                $icon
              >
                Try 7 Days for 0$
              </Button>
              <Text
                style={{
                  marginLeft: "8%",
                  fontWeight: "400",
                  fontSize: "18px",
                  color: "#7C7C7C",
                }}
              >
                Next —{" "}
                <span style={{ textDecoration: "line-through" }}>75$</span>{" "}
                28$ per year
              </Text>
            </div>
          ) : null}
        </div>
        {currentStep <= 10 ? (
          <div className={clsx.step_wrapper}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
              <div
                key={item}
                className={`${clsx.step_block} ${
                  currentStep >= item ? clsx.step_active : ""
                }`}
              ></div>
            ))}
          </div>
        ) : null}
      </form>
    </div>
  );
};

export default Auth;
