import styled from "styled-components";

export const TextArea = styled.textarea`
  max-height: 107px;
  min-height: 107px;
  padding: 16px 20px 16px 20px;
  background-color: #f5f6f6;
  border: none;
  border-radius: 33px;
  width: 100%;
  resize: none;
  font-size: 17px;
`;
