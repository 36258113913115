import { FC, useState } from "react";
import { Button } from "../../shared/button/Button";
import { Input } from "../../shared/input/Input";
import { SecondTitle } from "../../shared/secondTitle/SecondTitle";

import styles from "./ui/index.module.css";
import closeIcon from "../../assets/icons/close.svg";
import { ModalProps } from "./model/types";
import { useTelegramVerifyMutation } from "../../app/redux/product/apiProducts";
import { useNavigate } from "react-router-dom";

const Payment: FC<ModalProps> = ({ closeModal }) => {
  const navigate = useNavigate();
  const [verify] = useTelegramVerifyMutation();
  const [error, setError] = useState<any | null>(null);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    const formData = new FormData(e.currentTarget);
    const username = formData.get("username");

    try {
      if (!username || username === '@') {
        setError('You must fill in the username');
        return;
      }
      const cleanUsername = username.toString().startsWith('@') ? username.toString().slice(1) : username;
      const response = await verify(cleanUsername);
      if ('error' in response) {
        const errorData = response.error as any;
        const message = errorData?.data?.detail || errorData?.status === 404 
          ? 'User not found' 
          : 'An error occurred during verification';
        setError(message);
        return;
      }
      if (!response.data?.ok) {
        setError('Verification failed');
        return;
      }
      navigate(`/registration?step=10&payment=tg`);
    } catch (err) {
      setError('An unexpected error occurred');
    }
  };

  return (
    <div
      className={styles.content_wrapper}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.content}>
        <div
          className={styles.close_icon}
          onClick={() => {
            closeModal();
          }}
        >
          <img src={closeIcon} alt="close icon" />
        </div>

        <form onSubmit={onSubmit} className={styles.content_block}>
          <SecondTitle mb="20px">Link your Telegram account</SecondTitle>

          <Input name="username" placeholder="Enter your Telegram username" />
          {error && <div className={styles.error}>{error}</div>}
          <Button style={{ margin: "20px 0px" }} $bg $icon>
            Link
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Payment;
