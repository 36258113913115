import styles from "./ui/index.module.css";
import { useParams } from "react-router-dom";
import { ReactComponent as AddressIcon } from "../../assets/icons/adress.svg";
//import { ReactComponent as Car } from "../../assets/icons/Car.svg";
//import { ReactComponent as TV } from "../../assets/icons/TV.svg";
//import { ReactComponent as Cloud } from "../../assets/icons/cloud.svg";
//import { ReactComponent as Speaker } from "../../assets/icons/speaker.svg";
//import { ReactComponent as WiFi } from "../../assets/icons/wifi.svg";
//import { ReactComponent as Gym } from "../../assets/icons/gym.svg";

import ApartmentDetails from "../../shared/apartmentDetails/ApartmentDetails";
import { Text } from "../../shared/Text/Text";
import { Lessor } from "../../shared/lessor/Lessor";
import { LessorLeft } from "../../shared/lessor/LessorLeft";
import { LessorAvatar } from "../../shared/lessor/LessorAvatar";
import { LessorRight } from "../../shared/lessor/LessorRight";
import { SecondTitle } from "../../shared/secondTitle/SecondTitle";
import { Button } from "../../shared/button/Button";
import { useGetOneFlatQuery } from "../../app/redux/product/apiProducts";
import { useGetUserByIdQuery } from "../../app/redux/auth/authApi";
import { useModal } from "../../app/helpers/hooks/useModal";
import ContactsPopUp from "../contacts/ContactsPopUp";
import { useEffect } from "react";

const Details = () => {
  const { id } = useParams();
  const { data } = useGetOneFlatQuery({ id });

  const userId = data?.user_id || null;

  const res = useGetUserByIdQuery({ id: userId });

  const { isOpen, openModal, closeModal, ModalComponent } = useModal();

  const socialNetworks = {
    Instagram: res.data?.instagram,
    LinkedIn: res.data?.linkedin,
    WhatsApp: res.data?.whatsapp,
    Telegram: res.data?.tg_username,
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className={`${styles.details} container`}>
      {data && (
        <>
          <div className={styles.header_details}>
            {/* <Title>Cozy apartments in the San Blas area</Title> */}
            <div>
              <AddressIcon />
              <span>
                {data?.country}, {data.city}, {data.district}
              </span>
            </div>
          </div>
          <div className={styles.content}>
            <ApartmentDetails images={data.used_listing_pictures} />
            <div className={styles.text_content}>
              <div className={styles.date}>
                <p>
                  Departure date: <span>{data.date_from}</span>
                </p>
                <p>
                  Return to the apartment: <span>{data.date_to}</span>
                </p>
              </div>
              <Text>{data.description}</Text>

              <hr />
              <div className={styles.service}>
                <div className={styles.price}>
                  <p style={{ opacity: "1" }}>
                    Cost: <span>{data.price || "N/A"}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div>
        <Lessor
          className={styles.block}
          id="margin_top"
          style={{ cursor: "pointer" }}
          onClick={openModal}
        >
          <LessorLeft className={styles.block_left}>
            <LessorAvatar
              src={
                res?.data?.picture_url
                  ? res.data.picture_url
                  : "https://i.pinimg.com/564x/25/ee/de/25eedef494e9b4ce02b14990c9b5db2d.jpg"
              }
            />
            <div className={styles.host_text} style={{ width: "80%" }}>
              <SecondTitle>Hosted by {res?.data?.full_name}</SecondTitle>
              <Text>
                <span>{res?.data?.description}</span>
              </Text>
            </div>
          </LessorLeft>
          <LessorRight className={styles.block_right}>
            <SecondTitle style={{ color: "white", alignSelf: "flex-start" }}>
              Book an apartment
            </SecondTitle>
            <Text style={{ color: "white" }}>
              Contact {res?.data?.full_name} in any way convenient for you.
            </Text>
          </LessorRight>
        </Lessor>

        <Button
          onClick={openModal}
          $bg
          $icon
          style={{ margin: "auto", marginBottom: "180px" }}
        >
          Contact the owner
        </Button>
      </div>

      <ModalComponent>
        <ContactsPopUp
          data={res}
          socialNetworks={socialNetworks}
          closeModal={closeModal}
          openModal={openModal}
          isOpen={isOpen}
        />
      </ModalComponent>
    </div>
  );
};

export default Details;
