import { FC } from "react";
import { StepProps } from "./model/types";
import styles from "./ui/index.module.css";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { useModal } from "../../../app/helpers/hooks/useModal";
import Payment from "../../../features/payment/Payment";
import { Button } from "../../../shared/button/Button";
import { useNavigate } from 'react-router-dom';

const TelegramPayment: FC<StepProps> = () => {
  const { isOpen, openModal, closeModal, ModalComponent } = useModal();
  const navigate = useNavigate();

  const handleYesClick = (e: React.MouseEvent) => {
    e.preventDefault();
    openModal();
    return false;
  };


  return (
    <div className={styles.step2_wrapper}>
      <div style={{ marginTop: "15%" }} className={styles.step1_titles}>
        <SecondTitle fz="40px">Have you paid in Telegram?</SecondTitle>
        <div style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
          <div>
            <Button
              $bg
              $icon
              onClick={handleYesClick}
              value="true"
              name="telegram_payment"
            >
              Yes
            </Button>
          </div>
          <div>
            <Button
              $bg
              $icon
              value="false"
              name="telegram_payment"
            >
              No
            </Button>
          </div>
        </div>
      </div>
      <ModalComponent>
        <Payment
          isOpen={isOpen}
          openModal={openModal}
          closeModal={closeModal}
        />
      </ModalComponent>
    </div>
  );
};

export default TelegramPayment; 